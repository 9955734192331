import React from "react";
import {useRouteError} from "react-router-dom";
import { appLogger } from "../utils/logger";

function ErrorBoundary() {
  const error = useRouteError();
  appLogger.logError(
    `ErrorBoundary error!:${JSON.stringify(error)}`, false, 'ErrorBoundary'
  );
  return <div>Something went wrong. Please try again later.</div>;
}

export default ErrorBoundary;
