import { translate } from "../utils/translate";

export const ORDER_STATUS_PLACED_TIMESTAMP = 'orderPlacedDateTime';
export const ORDER_STATUS_PLACED_TIMESTAMP_ABBR = 'orderPlacedDateTimeInStoreAbbr';
export const ORDER_STATUS_RENTAL_START_DATE = 'rentalStartDate';
export const ORDER_STATUS_RENTAL_START_DATE_ABBR = 'rentalStartDateInStoreAbbr';
export const ORDER_STATUS_RENTAL_END_DATE = 'rentalEndDate';
export const ORDER_STATUS_RENTAL_END_DATE_ABBR = 'rentalStartDateInStoreAbbr';
export const ORDER_STATUS_REQUESTED_DATE_TIME = 'requestedDeliveryTime';
export const ORDER_STATUS_REQUESTED_DATE_TIME_ABBR = 'requestedDeliveryTimeInStoreAbbr';

export const ORDER_STATUS_DISPATCHED_TIMESTAMP = 'dispatchedStatusTimeInStore';
export const ORDER_STATUS_DISPATCHED_TIMESTAMP_ABBR = 'dispatchedStatusTimeInStoreAbbr';
export const ORDER_STATUS_SHIPMENT_ETA_TIMESTAMP = 'shipmentETAInStore';
export const ORDER_STATUS_SHIPMENT_ETA_TIMESTAMP_ABBR = 'shipmentETAInStoreAbbr';
export const ORDER_STATUS_IN_ROUTE_TIMESTAMP = 'inRouteStatusTimeInStore';
export const ORDER_STATUS_IN_ROUTE_TIMESTAMP_ABBR = 'inRouteStatusTimeInStoreAbbr';
export const ORDER_STATUS_ON_SITE_TIMESTAMP = 'onSiteStatusTimeInStore';
export const ORDER_STATUS_ON_SITE_TIMESTAMP_ABBR = 'onSiteStatusTimeInStoreAbbr';
export const ORDER_STATUS_COMPLETED_TIMESTAMP = 'completedStatusTimeInStore';
export const ORDER_STATUS_COMPLETED_TIMESTAMP_ABBR = 'completedStatusTimeInStoreAbbr';
export const ORDER_STATUS_EXCEPTION_TIMESTAMP = 'exceptionStatusTimeInStore';
export const ORDER_STATUS_EXCEPTION_TIMESTAMP_ABBR = 'exceptionStatusTimeInStoreAbbr';

export const ORDER_STATUSES = {
  CONVERTED: "Converted",
  NEW: "New",
  PICKED_UP: "Picked Up",
  DISPATCHED: "Dispatched",
  IN_ROUTE: "In Route",
  ON_SITE: "On Site",
  COMPLETED: "Completed",
  CANCELED: "Cancelled",
  EXCEPTION: "Exception",
  UNABLE_TO_COMPLETED: "Unable to Complete",
  COMPLETED_WITH_EXCEPTIONS: "Completed with Exceptions",
};

export const ORDER_STATUS_STEP: any = {
  [ORDER_STATUSES.CONVERTED]: 1,
  [ORDER_STATUSES.NEW]: 1,
  [ORDER_STATUSES.PICKED_UP]: 1,
  [ORDER_STATUSES.DISPATCHED]: 1,
  [ORDER_STATUSES.IN_ROUTE]: 2,
  [ORDER_STATUSES.ON_SITE]: 3,
  [ORDER_STATUSES.COMPLETED]: 4,
};

export const ORDER_HEADINGS: any = {
  [ORDER_STATUSES.CONVERTED]: translate('single_shipment:order_placed_heading'),
  [ORDER_STATUSES.NEW]: translate('single_shipment:order_placed_heading'),
  [ORDER_STATUSES.PICKED_UP]: translate('single_shipment:order_placed_heading'),
  [ORDER_STATUSES.DISPATCHED]: translate('single_shipment:order_placed_heading'),
  [ORDER_STATUSES.IN_ROUTE]: translate('single_shipment:order_on_the_way_heading'),
  [ORDER_STATUSES.ON_SITE]: translate('single_shipment:order_on_site_heading'),
  [ORDER_STATUSES.COMPLETED]: "", 
};

export const MULTI_SHIPMENT_ORDER_HEADINGS: any = {
  [ORDER_STATUSES.CONVERTED]: translate('multi_shipment:order_placed_heading'),
  [ORDER_STATUSES.NEW]: translate('multi_shipment:order_placed_heading'),
  [ORDER_STATUSES.PICKED_UP]: translate('multi_shipment:order_placed_heading'),
  [ORDER_STATUSES.DISPATCHED]: translate('multi_shipment:order_placed_heading'),
  [ORDER_STATUSES.IN_ROUTE]: translate('multi_shipment:order_on_the_way_heading'),
  [ORDER_STATUSES.ON_SITE]: translate('multi_shipment:order_on_site_heading'),
  [ORDER_STATUSES.COMPLETED]: translate('single_shipment:stepper_order_delivered'),
};

export const STEPPER_DEFAULT_STEPS = [
  {
    title: translate("single_shipment:stepper_order_placed"),
    timeKey: ORDER_STATUS_PLACED_TIMESTAMP,
    timeAbbrKey: ORDER_STATUS_PLACED_TIMESTAMP_ABBR,
    subtitle: "",
  }, // subtitle = value of time,
  {
    title: translate("multi_shipment:stepper_order_on_way"),
    timeKey: ORDER_STATUS_IN_ROUTE_TIMESTAMP,
    timeAbbrKey: ORDER_STATUS_IN_ROUTE_TIMESTAMP_ABBR,
    subtitle: "",
  }, // populated in component
  {
    title: translate("single_shipment:stepper_order_on_site"),
    timeKey: ORDER_STATUS_ON_SITE_TIMESTAMP,
    timeAbbrKey: ORDER_STATUS_ON_SITE_TIMESTAMP_ABBR,
    subtitle: "",
  },
  {
    title: translate("single_shipment:stepper_order_delivered"),
    timeKey: ORDER_STATUS_COMPLETED_TIMESTAMP,
    timeAbbrKey: ORDER_STATUS_COMPLETED_TIMESTAMP_ABBR,
    subtitle: "",
  },
];

export const FULFILLMENT_TYPE_DELIVERY = "DELIVERY";
export const FULFILLMENT_TYPE_PICKUP = "CUSTOMER_PICKUP";
export const RESERVATION_CANCELED_STATUS = "CANCELLED";
export const ITEM_STATUS_RETURNED = "RETURNED";
export const ORDER_STATUS_CLOSED = 'CLOSED';
export const STOP_ITEMS_MAPPING_FIELD = 'omsOrderLineId';
