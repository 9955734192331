const REACT_APP_BASE_URL = "";
const REACT_APP_CLIENT_ID = "";
const REACT_APP_CLIENT_SECRET = "";

const appEnv = {
  REACT_APP_BASE_URL,
  REACT_APP_CLIENT_ID,
  REACT_APP_CLIENT_SECRET,
};

export const setupEnvVariables = () => {
  return new Promise((resolve, reject) => {
    const el = document?.querySelector?.('meta[name="frontend-variables"]') as HTMLElement;
    if (el) {
      const APP_DOMAIN = el?.getAttribute('data-muledomain') ?? '';
      const APP_EXTENSION = 'web/api/v1/';
  
      appEnv.REACT_APP_BASE_URL = `${APP_DOMAIN}/${APP_EXTENSION}`;
      appEnv.REACT_APP_CLIENT_ID = (el?.getAttribute('data-openIdMuleClientId') || el?.getAttribute('data-clientid')) ?? '';
      appEnv.REACT_APP_CLIENT_SECRET = (el?.getAttribute('data-openIdMuleClientSecret') || el?.getAttribute('data-clientsecretcode')) ?? '';
  
      Object.freeze(appEnv);
      resolve(appEnv);
    } else {
      reject('meta tag for environment variables is not configured!');
    }
  })
};

export default appEnv;