/**
 * Pushes an event to the data layer for page user analytics tracking.
 * If data layers is not available, it logs a warning message. (This won't happen, just in case, warning msg to notify)
 *
 * @param {string} eventName - The name of the event to push to the data layer.
 * @param {object} payload - The data payload to be included with the event.
 * @returns {void}
 */
export const pushEventPageUserEventToDataLayer = (eventName: string, payload: object): void => {
  if (window && window.dataLayer)
  {window.dataLayer.push({
    event: eventName,
    ...payload
  });}
  else {console.warn('No datalayer available to push!');}
};

/**
 * Pushes an event to the data layer for form beign viewed for tracking and analytics purposes.
 * If data layers is not available, it logs a warning message. (This won't happen, just in case, warning msg to notify)
 *
 * @param {string} eventName - The name of the event to push to the data layer.
 * @param {string} formName - The name of the form that is being viewed.
 * @param {object} payload - The data payload to be included with the event.
 * @returns {void}
 */
export const pushEventToDataLayerForFormViewed =  (eventName: string, formName: string, payload: object): void => {
  if (window && window.dataLayer)
  {window.dataLayer.push({
    event: eventName,
    form_name: formName,
    ...payload
  });}
  else {console.warn('No datalayer available to push!');}
};


/**
 * Pushes an event to the data layer for entering data in the form for tracking and analytics purposes.
 * If data layers is not available, it logs a warning message. (This won't happen, just in case, warning msg to notify)
 *
 * @param {string} eventName - The name of the event to push to the data layer.
 * @param {string} formName - The name of the form that is being viewed.
 * @param {string} fieldName - The name of the form field that is being entered.
 * @param {object} payload - The data payload to be included with the event.
 * @returns {void}
 */
export const pushEventToDataLayerForFormStarted =  (eventName: string, formName: string, fieldName: string, payload: object): void => {
  if (window && window.dataLayer)
  {window.dataLayer.push({
    event: eventName,
    form_name: formName,
    field_name: fieldName,
    ...payload
  });}
  else {console.warn('No datalayer available to push!');}
};


/**
 * Pushes an event to the data layer for form error for tracking and analytics purposes.
 * If data layers is not available, it logs a warning message. (This won't happen, just in case, warning msg to notify)
 *
 * @param {string} eventName - The name of the event to push to the data layer.
 * @param {string} formName - The name of the form that is being viewed.
 * @param {string} fieldName - The name of the form field that is being entered.
 * @param {string} formError - The error beign displayed.
 * @param {object} payload - The data payload to be included with the event.
 * @returns {void}
 */
export const pushEventToDataLayerForFormError = (eventName: string,
  formName: string,
  fieldName: string,
  formError: string,
  payload: object): void => {
  if (window && window.dataLayer)
  {window.dataLayer.push({
    event: eventName,
    form_name: formName,
    field_name: fieldName,
    form_error: formError,
    ...payload
  });}
  else {console.warn('No datalayer available to push!');}
};


/**
 * Pushes an event to the data layer for form completion.
 * If data layers is not available, it logs a warning message. (This won't happen, just in case, warning msg to notify)
 *
 * @param {string} eventName - The name of the event to push to the data layer.
 * @param {string} formName - The name of the form to push to the data layer.
 * @param {object} payload - The data payload to be included with the event.
 * @returns {void}
 */
export const pushEventToDataLayerForCompleted = (eventName: string, formName: string, payload: object): void => {
  if (window && window.dataLayer)
  {window.dataLayer.push({
    event: eventName,
    form_name: formName,
    ...payload
  });}
  else {console.warn('No datalayer available to push!');}
};

/**
 * Pushes an event to the data layer for cta click.
 * If data layers is not available, it logs a warning message. (This won't happen, just in case, warning msg to notify)
 *
 * @param {string} eventName - The name of the event to push to the data layer.
 * @param {object} payload - The data payload to be included with the event.
 * @returns {void}
 */
export const pushEventForCtaClick = (eventName: string, payload: object): void => {
  if (window && window.dataLayer)
  {window.dataLayer.push({
    event: eventName,
    ...payload
  });}
  else {console.warn('No datalayer available to push!');}
};
