export const setCookie = (cookieName: string, cookieValue: string, days = 1) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = cookieName + "=" + (cookieValue || "")  + expires + "; path=/";
}

export const cookieValue = (cookieName: string): string => {
  const name = cookieName + "=";
  const cookieVal = document.cookie;
  const ca = cookieVal.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const deleteCookie = (cookieName: string): string => {
  return (document.cookie =
    cookieName +
    "=; Path=/; SameSite=None; Secure; Max-Age=0; Expires=Thu, 01 Jan 1970 00:00:01 GMT;");
};
