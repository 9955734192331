export const FORM_HEADING = 'login:form_heading';
export const FORM_HEADLINE = 'login:form_headline';
export const FORM_CONTRACT_FIELD_LABEL = 'login:form_contract_field_label';
export const FORM_COUNTRY_FIELD_LABEL = 'login:form_country_field_label';
export const FORM_ZIP_FIELD_LABEL = 'login:form_zip_field_label';
export const FORM_BUTTON_TEXT = 'login:form_button_text';
export const SUBTITLE_TEXT = 'login:subtitle_text';
export const SUBTITLE_TEXT_EMPLOYEE = 'login:subtitle_text_employee';
export const EMPTY_CONTRACT_MSG = 'login:empty_contract_msg';
export const EMPTY_ZIP_MSG = 'login:empty_zip_msg';
export const INCORRECT_CONTRACT_MSG = 'login:incorrect_contract_msg';
export const INCORRECT_ZIP_MSG = 'login:incorrect_zip_msg';
export const UNABLE_TO_FIND_MSG = 'login:unable_to_find_msg';
export const USER_UNAUTHENTICATED_MSG = 'login:user_unauthenticated_msg';
export const UNABLE_TO_FIND_MSG_EMPLOYEE = 'login:unable_to_find_msg_employee';
export const BANNER_TEXT = 'login:banner';
export const TRACKER_NOT_AVAILABLE = 'login:tracker_not_available';
export const ALIDA_SURVEY_TEXT = 'login:alida_survey_link_text';
export const ALIDA_SURVEY_LINK_TEXT = 'login:alida_survey_sub_text';
export const CONTRACT_MAX_LENGTH = 12;
export const CONTRACT_LENGTH = 12;
export const CONTRACT_MIN_LENGTH = 1;
export const ZIP_MAX_LENGTH = 7;
export const ZIP_MIN_LENGTH = 5;
export const ZIP_REGEX = /^(\d{5}|[A-Z]\d[A-Z] ?\d[A-Z]\d)$/;
export const ZIP_REGEX_CA = /^([A-Z]\d[A-Z] ?\d[A-Z]\d)$/i;
export const CONTRACT_REGEX = /^[0-9]{1,12}$/;
