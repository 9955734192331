import { PROJECT_ROOT_PATH } from "../../constants/routeConstants";
import {
  FULFILLMENT_TYPE_DELIVERY,
  FULFILLMENT_TYPE_PICKUP,
  ITEM_STATUS_RETURNED,
  ORDER_STATUSES,
  ORDER_STATUS_CLOSED,
  RESERVATION_CANCELED_STATUS,
  STOP_ITEMS_MAPPING_FIELD
} from "../../constants/orderConstants";
import {
  formatDateTime,
  formatTime,
  formatWeekDate,
} from "../../utils/dateUtils";
import { formatPhone, unformatPhone } from "../../utils/phoneUtils";
import type {
  Branch,
  Item,
  OrderDetails,
  Reservation,
  Shipment,
  Stop
} from "../contract/contractSlice";
import { ZIP_REGEX_CA } from "../../constants/loginFormConstants";
import { v4 as uuidv4 } from "uuid";
import { dateAdd, getUserDateTime } from "../../utils/timezoneUtils";

export const populateStepperDate = (
  timeKey: string,
  abbrKey: string,
  shipmentDetails: Shipment
) => {
  // if order dispatched show dispatched status time in state 0
  // commented this for future reference
  // if (key === ORDER_STATUS_PLACED_TIMESTAMP && shipmentDetails?.shipmentStatus === ORDER_STATUSES.DISPATCHED) {
  //   return formatDateTime(shipmentDetails?.[ORDER_STATUS_DISPATCHED_TIMESTAMP], true)
  // }

  const field = (shipmentDetails as any)?.[timeKey] ?? "";
  const fieldAbbr = (shipmentDetails as any)?.[abbrKey] ?? "";
  if (field) {
    return formatDateTime(field, fieldAbbr);
  }

  return "";
};

export const populateBranchDetails = (branchDetails: Branch | null) => {
  const branchId = branchDetails?.pc ?? "";
  const branchName = branchDetails?.name ?? "";
  const branchStreet = branchDetails?.street ?? "";
  const branchState = branchDetails?.state ?? "";
  const branchZip = branchDetails?.zip ?? "";
  const branchCity = branchDetails?.city ?? "";

  const branchPhone = branchDetails?.phone ?? "";

  const unformattedBranchPhone = unformatPhone(branchPhone);
  const formattedBranchPhone = formatPhone(unformattedBranchPhone);

  return {
    branchName,
    branchState,
    branchCity,
    branchId,
    branchZip,
    branchPhone,
    branchStreet,
    unformattedBranchPhone,
    formattedBranchPhone,
  };
};

export const getTotalItems = (items: Item[]) =>
  items.reduce((a, b) => a + b?.qty, 0);

export const isCompletedOrder = (orderDetails: OrderDetails) => {
  if (orderDetails?.orderStatus) {
    return orderDetails?.orderStatus === ORDER_STATUS_CLOSED
  }

  const items = orderDetails?.items ?? [];
  return items.every((item) => item.deliveryStatus === ITEM_STATUS_RETURNED);
}

export const isPickupOrder = (order: OrderDetails) => {
  return order.orderFulfillmentType === FULFILLMENT_TYPE_PICKUP;
};

export const isDeliveryOrder = (order: OrderDetails) => {
  return order.orderFulfillmentType === FULFILLMENT_TYPE_DELIVERY;
};

export const isReservationCanceled = (reservation: Reservation) => (
  reservation?.orderStatus === RESERVATION_CANCELED_STATUS
)

export const isPendingShipment = (shipment: Shipment) => (
  shipment.shipmentStatus === ORDER_STATUSES.DISPATCHED
    || shipment.shipmentStatus === ORDER_STATUSES.NEW
    || shipment.shipmentStatus === ORDER_STATUSES.CONVERTED
);

export const isPendingOrder = (stop: Stop) => (
  stop.shipments.every(shipment => isPendingShipment(shipment) && !shipment.isReDispatch)
);

export const isExceptionOrder = (stop: Stop) => (
  stop.shipments.every(shipment => shipment.shipmentStatus === ORDER_STATUSES.EXCEPTION)
);

export const isRedispatchedPendingOrder = (stop: Stop) => (
  stop.shipments.every(shipment => shipment.isReDispatch && isNewOrder(shipment))
)

export const isShipmentsDisplayedInBottom = (stop: Stop) => {
  const isPending = isPendingOrder(stop);

  const isException = stop.shipments.every(
    (shipment) => checkIsException(shipment)
  );

  return isException || isPending
}

export const generateShareShipmentURL = (token: string) => (
  `${window.location.origin}/${PROJECT_ROOT_PATH}/?guid=${token}`
);

export const isCanadianPostalCode = (postalCode: string | number) => ZIP_REGEX_CA.test(postalCode?.toString?.() ?? '');

export const getTrackerGUID = () => {
  const field = 'guid';
  const url = window.location.href;
  if (url.indexOf('?' + field + '=') !== -1 || url.indexOf('&' + field + '=') !== -1) {
    const query = window.location.search.substring(1);
    const details = parseQueryString(query);
    return details?.guid ?? null;
  } 
  return null;
}

export const isExludedPC = (pc: number, exclusionList: number[]) => (exclusionList ?? []).includes(pc);

export const mergePendingDeliveries = (stops: Stop[]) => {
  const hash = new Map();

  [...stops].forEach((stop) => {
    const deliveryId = isPendingOrder(stop) ? '-1' : stop.deliveryId;
    const existingData = hash.get(deliveryId);
    const existingShipments = existingData?.shipments ?? [];

    hash.set(
      deliveryId,
      {
        ...Object.assign(hash.get(deliveryId) || {}, stop),
        shipments: [
          ...existingShipments,
        ].concat(stop?.shipments ?? [])
      }
    )
  });

  return Array.from(hash.values());
}

export const filterStopItems = (stop: Stop, allItems: Item[]) => (
  allItems.filter(
    (item: any) => stop?.shipments.find(
      (s: Shipment) => item[STOP_ITEMS_MAPPING_FIELD] === s[STOP_ITEMS_MAPPING_FIELD]
    )
  )
);

const parseQueryString = (query: string) => {
  const vars = query.split("&");
  const queryString: Record<string, any> = {};
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    const key = decodeURIComponent(pair?.shift?.() ?? '');
    const value = decodeURIComponent(pair?.join?.("=") ?? '');
    // If first entry with this name
    if (typeof queryString[key] === "undefined") {
      queryString[key] = value;
      // If second entry with this name
    } else if (typeof queryString[key] === "string") {
      const arr = [queryString[key], value];
      queryString[key] = arr;
      // If third or later entry with this name
    } else {
      queryString[key].push(value);
    }
  }
  return queryString;
}

// exception helpers
export const checkAllItemsInShipmentFailed = (shipments: Shipment[]) => (shipments ?? []).every((shipment: Shipment) => shipment.isException);
export const checkShipmentRescheduled = (shipments: Shipment[]) => (shipments ?? []).some(
  (shipment: Shipment) => shipment.isReDispatch && isNewOrder(shipment)
);
export const checkSomeItemsInShipmentFailed = (shipments: Shipment[]) => (shipments ?? []).some((shipment: Shipment) => shipment.isException);
export const checkShipmentCompletedWithExceptions = (shipments: Shipment[]) => (
  !checkAllItemsInShipmentFailed(shipments) && checkSomeItemsInShipmentFailed(shipments)
);
export const checkIsException = (shipment: Shipment) => {
  // shipment status is exception
  if (shipment.shipmentStatus === ORDER_STATUSES.EXCEPTION) {
    return true;
  }

  // is redispatch but not in route yet,
  // acc to req: it should still show exception status for below condition
  if (shipment.isReDispatch && isNewOrder(shipment)) {
    return true;
  }

  return false;
}

export const isNewOrder = (shipment: Shipment) => {
  return (
    shipment.shipmentStatus === ORDER_STATUSES.NEW
    || shipment.shipmentStatus === ORDER_STATUSES.PICKED_UP
    || shipment.shipmentStatus === ORDER_STATUSES.CONVERTED
    || shipment.shipmentStatus === ORDER_STATUSES.DISPATCHED
  )
}

export const getLastUpdatedTime = () => {
  return getUserDateTime();
};

export const getFormattedShipmentETA = (shipmentETA: string, shipmentETAAbbr: string) => {
  const shipmentEndsETA = dateAdd(shipmentETA, 30, 'minutes');
  
  const shipmentStartETAWithDay = formatWeekDate(shipmentETA);
  const shipmentStartETATime = formatTime(shipmentETA);
  const shipmentEndETATime = formatTime(shipmentEndsETA, shipmentETAAbbr);

  return {
    shipmentStartETAWithDay,
    shipmentStartETATime,
    shipmentEndETATime
  }
};

// covert shipment timestamps from est to utc
export const convertShipmentTimestamps = (shipment: Shipment) => {
  const data = JSON.parse(JSON.stringify(shipment)); // clone shipment object

  // ** disable the coversion for now **

  // const timestampObjects = [
  //   "shipmentETA",
  //   "shipmentStatusTime",
  //   "inRouteStatusTime",
  //   "onSiteStatusTime",
  //   "dispatchedStatusTime",
  //   "completedStatusTime",
  //   "exceptionStatusTime",
  // ];

  // for (let i = 0; i < timestampObjects.length; i++) {
  //   const key = timestampObjects[i];
  //   const skipConversion = (
  //     !data[key]
  //     // || shipment.shipmentStatus === ORDER_STATUSES.DISPATCHED
  //     // || (
  //     //   key === ORDER_STATUS_SHIPMENT_ETA_TIMESTAMP
  //     //   && data[key]
  //     //   && isStartOfDay(data[key])
  //     // )
  //   );
  //   if (!skipConversion) {
  //     // covert est to utc
  //     data[key] = key === "shipmentETA" ? cstToUtc(data[key]) : estToUtc(data[key]);
  //   }
  // }

  // convert est to cst
  return data;
};

// convert reservation or contract timestamps to utc timezone
export const convertReservationOrContractTimestamps = (orderDetails: OrderDetails) => {
  const data = JSON.parse(JSON.stringify(orderDetails)); // clone orders object

  // ** disable the coversion for now **

  // const timestampObjects = [
  //   "orderPlacedDateTime",
  //   "rentalStartDate",
  //   // "rentalEndDate", // todo enable rental end date, confirmation required from product team
  //   "requestedDeliveryTime",
  // ];

  // for (let i = 0; i < timestampObjects.length; i++) {
  //   const key = timestampObjects[i];

  //   if (data[key]) {
  //     // covert to utc
  //     data[key] = offsetDateToUtc(data[key]);
  //   }
  // }

  return data;
};


export const mergeDuplicateItems = (items: Item[], merchCheck = false) => {
  const mergedItems: { [key: string]: Item } = {};

  for (const item of items) {
    const {
      itemUniqueId,
      omsOrderLineId,
      itemCatClass,
      qty = 0,
      name,
      isMerch,
      isBulk
    } = item;
    const mergeId = itemCatClass ?? name;
    const uniqueId = itemUniqueId ?? omsOrderLineId ?? uuidv4();
    const isRollup = !merchCheck || (merchCheck && (isMerch === "Y" || isBulk === "Y"));
    const isUnassignedCatClass = isNaN(Number(itemCatClass)) || Number(itemCatClass) === 0;

    if (!isUnassignedCatClass && isRollup && mergeId) {
      if (mergedItems[mergeId]) { // duplicate item
        mergedItems[mergeId].qty += qty;
      } else { // fresh item
        mergedItems[mergeId] = { ...item };
      }
    } else { // items to which merge not required
      mergedItems[uniqueId] = { ...item };
    }
  }

  return Object.values(mergedItems);
};
