import { STORAGE_CONFIG } from "../constants/storageConstants";
import { EMPLOYEE } from "../context/analyticsContext/analytics.constants";

export const isEmployeeUser = () => {
  const userType = JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.USERPROFILE) || '{}')?.type;
  return userType === EMPLOYEE;
}

export const saveCompanyId = (companyId: string | number) => {
  const cId = `${Number(companyId)}`;

  sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.COMPANY_ID, cId);
}

export const getCompanyId = () => {
  const value = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.COMPANY_ID);

  return Number(value);
}

export const getSBSID = () => {
  const value = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.SBSID);

  return `${value ?? ''}`;
}

export const getAuthUserProfile = () => {
  const userProfile = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.PROFILERESPONSE) || '{}')?.generateCustomerTokenUsingOkta;

  return userProfile ?? null;
}

export const getAuthUserType = () => {
  const userTypeStorage: string | null = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.USERTYPE);

  return userTypeStorage ?? null;
}