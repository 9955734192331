/** global constants **/
export const CST_TIMEZONE = "America/Chicago";
export const EST_TIMEZONE = "America/New_York";

export const ORDER_TYPE_RESERVATION = "reservation";
export const ORDER_TYPE_CONTRACT = "contract";

export const GLOBAL_BACK_BTN_TEXT = "global:back_btn_text";
export const GLOBAL_REFRESH_BTN_TEXT = "global:refresh_btn_text";
export const ITEMS_HEADING = "single_shipment:items_heading";

export const API_GENERIC_ERROR = "global:api_generic_error";
export const ALERT_TYPE_SUCCESS = "success";
export const ALERT_TYPE_ERROR = "error";

export const SHIMMER_TIMEOUT = 40000; // 40 sec

export const COUNTRY_DROPDOWN_OPTIONS = 
[
  {
    label: 'United States',
    value: 1,
  },
  {
    label: 'Canada',
    value: 2,
  }
];
