import instance from "./config/client";
import {
  GET_STORE_LOCATIONS_URL,
  FETCH_ORDERS_API_URL,
  FETCH_SHIPMENTS_API_URL,
  GET_INC_EXC_PC_LIST_URL,
  FETCH_SHARE_DELIVERY_TOKEN_URL,
  SUBMIT_SHARE_DELIVERY_DETAILS_URL,
} from "../constants/apiConstants";
import { appLogger } from "../utils/logger";
import { generateShareShipmentURL } from "../modules/shipmentStatus/shipmentHelpers";

const verifyOptionalJWTToken = (data: any, headers: any) => {
  const jwtTokenValue = (headers?.AuthorizationJWT ?? '')
    .toLowerCase()
    .replace('bearer', '')
    .trim();
  
  if (jwtTokenValue === "") {
    delete headers.AuthorizationJWT;
  }
  return data;
};

const getContracts = async (
  contractId: string,
  postalCode: string,
  isAuthenticated: boolean,
  isEmployee: boolean
) => {
  const targetUrl = `${FETCH_ORDERS_API_URL}/${contractId}`;
  const optionalSuffixForPostalCode = `anonymous?postalCode=${postalCode}`;
  try {
    const url = !isEmployee ? `${targetUrl}/${optionalSuffixForPostalCode}` : targetUrl;
    const { data } = await instance.get(url,
      {
        transformRequest: [
          verifyOptionalJWTToken
        ]
      }
    );
    return data;
  } catch (e: any) {
    appLogger.logError(
      e?.message ?? 'getContracts API failed',
      false,
      "getContracts",
      {
        isAuthenticated,
        isEmployee,
        contractId,
        postalCode
      }
    );
    throw e;
  }
};

const getContractsThroughToken = async (token: string) => {
  try {
    const { data } = await instance.get(FETCH_ORDERS_API_URL,
      {
        headers: {
          token,
        },
        transformRequest: [
          verifyOptionalJWTToken
        ]
      }
    );
    return data;
  } catch (e: any) {
    appLogger.logError(
      e?.message ?? 'getContractsThroughToken API failed',
      false,
      "getContractsThroughToken",
      token
    );
    throw e;
  }
};

const getShipments = async (contractId: string) => {
  try {
    const { data } = await instance.get(
      `${FETCH_SHIPMENTS_API_URL(contractId)}`,
    );
    return data;
  } catch (e: any) {
    appLogger.logError(
      e?.message ?? 'getShipments API failed',
      false,
      "getShipments",
      contractId
    );
    throw e;
  }
};

const getShareDeliveryToken = async (contractId: string) => {
  try {
    const { data } = await instance.get(
      `${FETCH_SHARE_DELIVERY_TOKEN_URL(contractId)}`,
    );
    return data;
  } catch (e: any) {
    appLogger.logError(
      e?.message ?? 'getShareDeliveryToken API failed',
      false,
      "getShareDeliveryToken",
      contractId
    );
    throw e;
  }
}

const getBranchDetails = async (pc: number | string) => {
  try {
    const { data, status } = await instance.get(
      GET_STORE_LOCATIONS_URL + "/" + pc,
      {
        headers: {
          "Content-Encoding": "application/gzip",
        },
      }
    );
    return { data, status };
  } catch (e: any) {
    appLogger.logError(
      e?.message ?? 'getBranchDetails API failed',
      false,
      "getBranchDetails",
      pc
    );
    throw e;
  }
};

const getIncExcPcList = async () => {
  try {
    const { data } = await instance.get(
      GET_INC_EXC_PC_LIST_URL,
      {
        headers: {
          "Content-Encoding": "application/gzip",
        },
      }
    );

    return data;
  } catch (e: any) {
    appLogger.logError(e, false, "getIncExcPcList");
    throw e;
  }
};

const submitShareDeliveryDetails = async ({
  contractOrReservationId,
  token,
  firstName,
  lastName,
  email,
  phoneNumber,
  orderType
}: {
  contractOrReservationId: string;
  token: string;
  firstName: string;
  lastName: string;
  email?: string;
  phoneNumber?: string;
  orderType: 'R' | 'O'
}) => {
  try {
    const { data } = await instance.post(
      SUBMIT_SHARE_DELIVERY_DETAILS_URL(contractOrReservationId),
      {
        dtLink: generateShareShipmentURL(token),
        ...(email && {email}),
        ...(phoneNumber && {phone: phoneNumber}),
        firstName,
        lastName,
        orderType
      },
      {
        headers: {
          "Content-Encoding": "application/gzip",
        },
      }
    );

    return data;
  } catch (e: any) {
    appLogger.logError(
      'submitShareDeliveryDetails call failed!',
      false,
      "submitShareDeliveryDetails",
      {
        contractOrReservationId,
        token,
        firstName,
        lastName,
        email,
        phoneNumber,
        orderType
      }
    );
    throw e;
  }
};

export {
  // get
  getContracts,
  getShipments,
  getBranchDetails,
  getIncExcPcList,
  getShareDeliveryToken,
  getContractsThroughToken,
  // post
  submitShareDeliveryDetails
};
