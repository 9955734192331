// import {ENV_CONFIG} from "./envUtils";
import appEnv from "../env";
import { cookieValue } from "./cookieUtils";
import { appLogger } from "./logger";

/* interface ApiConfig {
  baseURL: string;
  timeout: number;
  headers: {
    Accept: string;
    "Content-Type": string;
    client_secret: string;
    client_id: string;
    channel: string;
  };
} */
export const getApiConfigByEnv = () => {
  try {
    // const frontendConfig = ENV_CONFIG.FRONTEND_CONFIG;
    // const muleDomain = frontendConfig?.muleDomain;
    // const clientId = frontendConfig?.clientid;
    // const clientSecret = frontendConfig?.clientsecretcode;
    const muleDomain = appEnv.REACT_APP_BASE_URL;
    const clientId = appEnv.REACT_APP_CLIENT_ID;
    const clientSecret = appEnv.REACT_APP_CLIENT_SECRET;
    return {
      baseURL: muleDomain, // api domain
      timeout: 45000,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        client_secret: clientSecret,
        client_id: clientId,
        channel: "WEBAPP",
      },
    };
  } catch (e) {
    appLogger.logError(
      'getApiConfigByEnv error',
      false,
      'getApiConfigByEnv',
    )
  }
};

export const generateImageURL = (id: string) =>
  `https://media.sunbeltrentals.com/is/image/sunbeltrentals/catClass_${id}?fmt=webp`;

export const getProjectsFromCookie = (): { [key: string]: string } => {
  const settingsCookie = cookieValue("Settings");
  const projectObj: { [key: string]: string } = {};
  if (settingsCookie) {
    const projectsInfo = settingsCookie.split("&");
    for (let i = 0; i < projectsInfo.length; i++) {
      const [key, value] = projectsInfo[i].split("=");
      projectObj[key] = decodeURIComponent(value);
    }
  }
  return projectObj;
};

export const hideShimmer = () => {
  const el = document?.getElementById?.('dt_shimmer_landing_form_el1') ?? null;
  if (el) {
    el.style.display = 'none'; // fallback if remove does not work
    el?.getElementsByTagName?.("style")?.[0]?.remove();
    el?.remove?.();
  }
};
