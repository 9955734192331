export const STORAGE_CONFIG = {
  SESSION_STORAGE: {
    USERTYPE: 'userType',
    OVERRIDEPC: 'overridePc',
    PROFILERESPONSE: 'profileResponse',
    LOCATIONPCOBJ: 'locationPCObj',
    COMPANY_ID: 'COMPANYID',
    SBSID: 'sbsid'
  },
  COOKIES: {
    REFRESHTOKEN: 'refreshtoken',
    REFRESH_TOKEN: 'refresh_token',
  },
  LOCAL_STORAGE: {
    ISCREDITNEWADDRESS: 'isCreditNewAddress',
    VIEWCART: 'viewCart',
    USERPROFILE: 'userProfile'
  }
};
