import enMessagesCoreComponents from "./i18n/en.json";

export const messages = { ...enMessagesCoreComponents };
export const locale = "en";
const supportedLanguages = ["en", "fr"];

// detect locale
let language = '';
// 1. <html> lang attribute
if (
  document &&
  document.documentElement &&
  typeof document.documentElement.getAttribute === "function"
) {
  language = document.documentElement.getAttribute("lang") ?? '';
}
// 2. path (index = 1)
if (!language && window) {
  const matches = window.location.pathname.match(/\/([a-zA-Z-]*)/g);
  if (matches instanceof Array && typeof matches[1] === "string") {
    language = matches[1].replace("/", "");
  }
}
// 3. subdomain (index = 0)
if (!language && window) {
  const matches = window.location.href.match(
    // eslint-disable-next-line no-useless-escape
    /(?:http[s]*\:\/\/)*(.*?)\.(?=[^\/]*\..{2,5})/gi
  );
  if (matches instanceof Array && typeof matches[0] === "string") {
    language = matches[0]
      .replace("http://", "")
      .replace("https://", "")
      .replace(".", "");
  }
}
// check compatibility
if (language) {
  while (
    supportedLanguages.indexOf(language) < 0 &&
    language.indexOf("-") > 0
  ) {
    language = language.substr(0, language.lastIndexOf("-"));
  }
}

// fallback
if (supportedLanguages.indexOf(language ?? '') < 0) {
  language = "en";
}

function loadLocaleData() {
  return { locale: language, messages }; //import(/* webpackChunkName: "i18n/[request]" */ `../../i18n/${language}`);
}

export default loadLocaleData;
