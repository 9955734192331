import { v4 as uuidv4 } from "uuid";
import type { SentryUser } from "./types";

export const generateCorrelationId = () => {
  return uuidv4();
};

interface AppLogger {
  isReady: boolean;
  logger: any;
  init: () => void;
  generateCorrelationId: () => void;
  logSuccess: (message: string) => void;
  logWarning: (message: string) => void;
  logError: (
    errorMessage: string,
    isWireCross?: boolean,
    method?: string,
    ...payload: any
  ) => void;
  clearSentryScope: () => void;
  logSentryUserId: (user: SentryUser) => void;
  setSentryScopeforSettings: (settings: any) => void;
  getEnv: () => string;
  setSentryScope: (type: string, cartId: string, tags: {key: string, value: string}[]) => void;
}

// Class logger utity function using anonymous function
// * this code works without the need to use class pollyfill
// * to avoid pollyfill injection while building the code.
export const appLogger: AppLogger = (() => {
  const loggerUtils: AppLogger = {
    isReady: false,

    logger: undefined,

    /** initialize sentry for logging */
    init: function () {
      this.logger = window.logger;
      
      if (!this.logger?.initSentry) {
        console.error('Sentry cannot be initialized!');
        return;
      }

      this.logger.initSentry();

      // logger is now ready
      this.isReady = true;
    },

    generateCorrelationId: function () {
      if (!this.logger?.generateCorrelationId) {
        console.error('Correlation id generator not available!');
        return generateCorrelationId();
      }

      return this.logger.generateCorrelationId();
    },

    logSuccess: function (message: string) {
      if (!this.logger?.logSuccess) {
        console.error('logSucccess not available!');
        console.log('success: ', message);
        return;
      }
      this.logger.logSuccess(message)
    },

    logWarning: function (message: string) {
      if (!this.logger?.logWarning) {
        console.error('logWarning not available!');
        console.warn('warning: ', message);
        return;
      }
      this.logger.logWarning(message)
    },

    logError: function (
      errorMessage: string,
      isWireCross = false,
      method = '',
      ...payload: any
    ) {
      if (!this.logger?.logError) {
        console.error('logWarning not available!');
        console.error('error: ', errorMessage, isWireCross, method, payload);
        return;
      }

      this.logger.logError(errorMessage, isWireCross, method, ...payload);
    },

    clearSentryScope: function () {
      if (!this.logger?.clearSentryScope) {
        console.error('clearSentryScope not available!');
        return;
      }

      this.logger?.clearSentryScope();
    },

    logSentryUserId: function(user: SentryUser) {
      if (!this.logger?.logSentryUserId) {
        console.error('logSentryUserId not available!');
        return;
      }

      this.logger.logSentryUserId(user);
    },

    setSentryScopeforSettings: function(settings: any) {
      if (!this.logger?.setSentryScopeforSettings) {
        console.error('setSentryScopeforSettings not available!');
        return;
      }

      this.logger.setSentryScopeforSettings(settings);
    },

    setSentryScope: function(type = 'guest', cartId = '', tags: any = []) {
      if (!this.logger?.setSentryScope) {
        console.error('setSentryScope not available!');
        return;
      }

      this.logger.setSentryScope(type, cartId, tags);
    },

    getEnv: function() {
      if (!this.logger?.getEnv) {
        console.error('getEnv not available!');
        return '';
      }

      return this.logger.getEnv();
    }
  };

  return loggerUtils;
})();


