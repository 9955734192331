import { STORAGE_CONFIG } from "../../constants/storageConstants";
import { USER_TYPE } from "../../constants/userDetailsConstants";
import { getProjectsFromCookie } from "../../utils/commonUtils";
interface PayloadCallback {
    (payload: any): void;
}

const getBussinessSize = (userType: string) => {
  if (userType === USER_TYPE.CREDIT) {
    return BUSINESS_SIZE.MIDDLE;
  } else if (userType === USER_TYPE.CASH) {
    return BUSINESS_SIZE.SMALL;
  }
};

export const PAYLOAD_METHODS = {
  payloadForUserDataPageView: (userType: string, callback: PayloadCallback) => {

    const NewAddress = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS)
      ? JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS) || '{}')
      : false;
    
    const viewCartData =
            (localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.VIEWCART) &&
                JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.VIEWCART)||'{}')) ||
            {};
    const cookieAccount = getProjectsFromCookie()
    
    const bussinessSize = getBussinessSize(userType) || ANALYTICS_EVENT_CONSTANTS.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
    const overridePc = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
    const userProfile = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.PROFILERESPONSE) || '{}')?.generateCustomerTokenUsingOkta;
    
    const address = {
      line_1:
                (userType === USER_TYPE.CREDIT && !NewAddress ? cookieAccount?.SLA : viewCartData?.location) ||
                ANALYTICS_EVENT_CONSTANTS.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
      line_2:
                (userType === USER_TYPE.CREDIT && !NewAddress
                  ? cookieAccount?.projectAddress2
                  : viewCartData?.jobSiteAddr2) || ANALYTICS_EVENT_CONSTANTS.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
      city:
                (userType === USER_TYPE.CREDIT && !NewAddress ? cookieAccount?.SLC : viewCartData?.jobSiteCity) ||
                ANALYTICS_EVENT_CONSTANTS.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
      state:
                (userType === USER_TYPE.CREDIT && !NewAddress ? cookieAccount?.SLS : viewCartData?.jobSiteState) ||
                ANALYTICS_EVENT_CONSTANTS.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
      zipcode:
                (userType === USER_TYPE.CREDIT && !NewAddress ? cookieAccount?.SLZ : viewCartData?.jobSiteZip) ||
                ANALYTICS_EVENT_CONSTANTS.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY
    };
    
    const nearest_pc = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ||'{}')
      ? JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ)||"{}")?.distance
      : ANALYTICS_EVENT_CONSTANTS.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
    const override_pc = overridePc?.pc || ANALYTICS_EVENT_CONSTANTS.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
        
    const user = {
      id: userProfile?.swsguid,
      account_payment_type: userType,
      authentication: userType !== USER_TYPE.GUEST ? true : false,
      account: cookieAccount?.CurrentWynneAccount || ANALYTICS_EVENT_CONSTANTS.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
      project: cookieAccount?.projectName || ANALYTICS_EVENT_CONSTANTS.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
      business_size: bussinessSize,
      is_employee: userProfile?.type === EMPLOYEE,
      location_set: address?.line_1 ? true : false,
      nearest_pc,
      override_pc,
      address: address
            
    };
    callback({user});
  },
};

export const ANALYTICS_EVENT_CONSTANTS = {
  EVENTS: {
    PAGE_USER: "user_data_provided",
    FORM_VIEWED: "form_viewed",
    FORM_STARTED: "form_started",
    FORM_ERROR: "form_error",
    FORM_COMPLETED: "form_completed",
    CTA_CLICKED: "cta_clicked",
    PAGE_VIEW: "page_viewed",
    DELIVERY_TRACKED: "delivery_tracked",
  },
  FORM_NAME: "Delivery tracker",
  SHARE_MODAL_FORM: "modal - share delivery tracker",
  ECOMMERCE: {
    UNDEFINED: 'undefined',
    UNDEFINED_WITHOUT_STRINGIFY: undefined
  },
  IS_SUBMIT: "form_submitted",
  PAYLOAD: {
    LINK: {
      "link_type": "anchor", 
      "link_location": "body", 
    }
  }
}

export const BUSINESS_SIZE = {
  SMALL: 'small',
  MIDDLE: 'middle',
  BIG: 'big'
};

export const EMPLOYEE = 'employee';

export const SHARE_MODAL_CLOSE_EVENT_PAYLOAD = {
  CLOSE_MODAL: {
    "link_name": "close",
    "link_type": "anchor",
    "link_location": "body",
    "link_destination": "#close"
  },
  LINK_COPIED: {
    "shared": "copied"
  } 
}

export const SHIPMENT_DETAILS_EVENT_PAYLOAD = {
  BRANCH_PHONE_CTA_CLICK: {
    "link_type": "anchor",
    "link_location": "body",
  }
    
}
