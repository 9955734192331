export const AUTH_TOKEN_API_URL = 'oauth2/token';
export const FETCH_ORDERS_API_URL = 'dt/orders';
export const GET_STORE_LOCATIONS_URL = 'locations';
export const GET_INC_EXC_PC_LIST_URL = 'contents/trackerpcList?inclusionList=yes&exclusionList=yes';

export const FETCH_PAGE_FIELDS_URL = (pageId: string) => `contents/labels?pageId=${pageId}`
export const FETCH_SHIPMENTS_API_URL = (orderId: string) => `dt/orders/${orderId}/shipments`;
export const FETCH_SHARE_DELIVERY_TOKEN_URL = (orderId: string) => `dt/orders/${orderId}/token`;
export const SUBMIT_SHARE_DELIVERY_DETAILS_URL = (orderId: string) => `dt/orders/${orderId}/tracking`;

export const GENERATE_PDP_PAGE_URL = 'https://mcstaging.sunbeltrentals.com/graphql';
