import { createAppSlice } from "../../store/createAppSlice";
import {
  getBranchDetails,
  getContracts,
  getContractsThroughToken,
  getIncExcPcList,
  getShareDeliveryToken,
  getShipments,
  submitShareDeliveryDetails,
} from "../../services/contractService";
import { getAuthToken } from "../../services/authService";
import { setCookie } from "../../utils/cookieUtils";
import {
  ORDER_TYPE_CONTRACT,
  ORDER_TYPE_RESERVATION,
} from "../../constants/appConstants";
import type { ShareModalSubmitPayload } from "../shipmentStatus/shareModal/ShareModal";
import { isCanadianPostalCode } from "../shipmentStatus/shipmentHelpers";
import { incExcPcDataTransformer, ordersDataTransformer } from "../../transformers/orderTransformers";
import { transformShipmentResponse } from "../../transformers/shipmentTransformers";
import { saveCompanyId } from '../../utils/authUtils';
import { appLogger } from "../../utils/logger";

export interface Contract {
  contractId: string;
  origReservationId?: string;
  orderPlacedDateTime?: string;
  orderPlacedDateTimeInStoreAbbr?: string;
  rentalStartDate?: string;
  rentalStartDateInStoreAbbr?: string;
  rentalEndDate?: string | null;
  requestedDeliveryTime?: string | null;
  requestedDeliveryTimeInStoreAbbr?: string | null;
  orderFulfillmentType?: string;
  orderStatus?: string;
  items: Item[];
  pc: {
    number: number | string;
  };
}

export interface Account {
  accountName: string;
  accountId: string;
}

export interface Jobsite {
  jobName: string;
  jobAddressLine1: string;
  jobAddressLine2: string;
  jobState: string;
  jobCity: string;
  jobZip: string;
}

export type YesNoOption = "N" | "Y";

export interface Item {
  name: string;
  qty: number;
  orderLineId: number;
  isBulk: YesNoOption;
  isMerch: YesNoOption;
  assetId?: string;
  omsOrderLineId?: number;
  deliveryStatus: string;
  itemUniqueId?: number;
  itemCatClass?: string;
  itemAssetNumber?: number | string | null;
}

export interface Reservation {
  origReservationId: number | null;
  rentalStartDate: string;
  rentalStartDateInStoreAbbr: string;
  rentalEndDate: string | null;
  requestedDeliveryTime: string | null;
  requestedDeliveryTimeInStoreAbbr: string | null;
  orderFulfillmentType: string;
  orderStatus?: string;
  items: Item[];
  pc: {
    number: number | string;
  };
}

export interface Stop {
  deliveryId: number | string;
  shipments: Shipment[];
  items?: Item[];
}

export interface Shipment {
  shipmentStatus: string;
  shipmentStatusCode: number;
  shipmentETAInStore: string | null;
  shipmentETAInStoreAbbr: string;
  orderPlacedDateTimeInStore?: string;
  orderPlacedDateTimeInStoreAbbr?: string;
  itemUniqueId: number | null;
  shipmentStatusTimeInStore: string | null;
  shipmentStatusTimeInStoreAbbr: string;
  inRouteStatusTimeInStore: string | null;
  inRouteStatusTimeInStoreAbbr: string;
  onSiteStatusTimeInStore: string | null;
  onSiteStatusTimeInStoreAbbr: string;
  dispatchedStatusTimeInStore: string | null;
  dispatchedStatusTimeInStoreAbbr: string;
  omsOrderLineId: number;
  deliveryId?: number;
  completedStatusTimeInStore: string | null;
  completedStatusTimeInStoreAbbr: string;
  exceptionStatusTimeInStore: string | null;
  exceptionStatusTimeInStoreAbbr: string;
  pcTimeZoneOffset: number;
  jobsiteLatitude: number;
  jobsiteLongitude: number;
  wynneUniqueId?: unknown;
  exceptionReason: unknown;
  isOH: boolean;
  isReDispatch: boolean;
  isException?: boolean;
}

export type OrderDetails = Contract | Reservation | Record<string, never>;

export type ApiResponse = {
  type: "success" | "error";
  message: string;
};

export type OrdersData = {
  account?: Account;
  reservation: Reservation;
  contracts: Contract[];
  jobsite?: Jobsite;
};

export type IncExcPcListResponse = {
  inclusionList: string[];
  exclusionList: string[];
};

export type IncExcPcListData = {
  inclusionList: number[];
  exclusionList: number[];
};

export interface AuthoringLabels {
  employeeFormLabel: string;
  formLabel: string;
  employeeReservationcontractLabel: string;
  reservationCountryLabel: string;
  reservartioncontractLabel: string;
  subTitle: string;
  employeeRequiredFieldLabel: string;
  title: string;
  trackDeliveryLabel: string;
  zippostalcodeLabel: string;
  trackingNotAvailableMessage: string;
  alidaSurveySubText: string;
  alidaSurveyLinkText: string;
  alidaSurveyLinkUrl: string;
  enableAlidaSurvey: boolean;
  enableLandingPageWarningMessage: boolean;
  landingPageWarningMessage: string;
}

export interface ContractSlice {
  selectedContractOrReservationId: string;
  selectedOrderType: string; // reservation or contract
  contractOrReservationId: string;
  zipOrPostalCode: string;
  data: OrdersData;
  stops: Stop[];
  shipments: Shipment[];
  branch: Branch | null;
  skipSelection: boolean;
  orderDetails: OrderDetails;
  isLoading: boolean;
  isTokenLoading: boolean;
  error: string;
  isShareModalLoading: boolean;
  shareModalError: string;
  shareModalIsPhoneSuccess: boolean;
  shareModalIsEmailSuccess: boolean;
  shareDeliveryToken: string;
  shareDeliveryFailed: boolean;
  shareDeliverySubmitted: boolean;
  shareDeliveryMessage: string;
  isExcIncListLoaded: boolean;
  isExcIncListFailed: boolean;
  inclusionList: number[];
  exclusionList: number[];
  shipmentDetailsLoading: boolean;
  shipmentApiError: string;
  branchDetailsLoading: boolean;
  currentPathname: string;
  isPageRefreshed: boolean;
  userProfile: null | Record<string, any>;
  isUserProfileLoaded: boolean;
  authoringLabels: AuthoringLabels;
  isLabelsLoaded: boolean;
}

export interface Branch {
  pc: number;
  name: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  companyId: number;
  email?: string;
  phone?: string;
}

export const initialState: ContractSlice = {
  selectedContractOrReservationId: "",
  selectedOrderType: "",
  contractOrReservationId: "",
  zipOrPostalCode: "",
  data: {
    account: {
      accountName: "",
      accountId: "",
    },
    reservation: {
      origReservationId: null,
      rentalStartDate: "",
      rentalStartDateInStoreAbbr: "",
      rentalEndDate: null,
      requestedDeliveryTime: null,
      requestedDeliveryTimeInStoreAbbr: '',
      orderFulfillmentType: "DELIVERY",
      items: [],
      pc: {
        number: "",
      },
    },
    contracts: [],
    jobsite: {
      jobName: "",
      jobAddressLine1: "",
      jobAddressLine2: "",
      jobCity: "",
      jobState: "",
      jobZip: "",
    },
  },
  stops: [],
  shipments: [],
  branch: {
    pc: 0,
    name: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    companyId: 1,
  },
  skipSelection: false,
  orderDetails: {},
  isLoading: false,
  isTokenLoading: true,
  error: "",
  isShareModalLoading: false,
  shareModalError: "",
  shareModalIsPhoneSuccess: false,
  shareModalIsEmailSuccess: false,
  shareDeliveryToken: "",
  shareDeliveryMessage: "",
  shareDeliverySubmitted: false,
  shareDeliveryFailed: false,
  isExcIncListLoaded: false,
  isExcIncListFailed: false,
  inclusionList: [],
  exclusionList: [],
  shipmentDetailsLoading: false,
  branchDetailsLoading: false,
  shipmentApiError: "",
  currentPathname: "",
  isPageRefreshed: false,
  userProfile: null,
  isUserProfileLoaded: false,
  authoringLabels: {
    employeeFormLabel: '',
    formLabel: '',
    employeeReservationcontractLabel: '',
    reservationCountryLabel: '',
    reservartioncontractLabel: '',
    subTitle: '',
    employeeRequiredFieldLabel: '',
    title: '',
    trackDeliveryLabel: '',
    zippostalcodeLabel: '',
    trackingNotAvailableMessage: '',
    alidaSurveySubText: '',
    alidaSurveyLinkText: '',
    alidaSurveyLinkUrl: '',
    enableAlidaSurvey: false,
    enableLandingPageWarningMessage: true,
    landingPageWarningMessage: ''
  },
  isLabelsLoaded: false,
};

// If you are not using async thunks you can use the standalone `createSlice`.
export const contractSlice = createAppSlice({
  name: "contract",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: (create) => ({
    setCurrentPathname: create.reducer<string>((state, action) => {
      state.currentPathname = action.payload ?? "";
    }),
    setIsPageRefreshed: create.reducer<boolean>((state, action) => {
      state.isPageRefreshed = !!action.payload;
    }),
    setAuthoringLabels: create.reducer<AuthoringLabels>((state, action) => {
      state.authoringLabels = action.payload;
      state.isLabelsLoaded = true;
    }),
    setUserProfile: create.reducer<any>((state, action) => {
      state.userProfile = action.payload;
      state.isUserProfileLoaded = true;
    }),
    resetStateData: create.reducer((state) => {
      // Object.assign(state, initialState); // uncomment for full reset ( not recommended )
      state.selectedContractOrReservationId = "";
      state.selectedOrderType = "";
      state.contractOrReservationId = "";
      state.zipOrPostalCode = "";
      state.shipments = [];
      state.stops = [];
      state.orderDetails = {};
      state.shipmentDetailsLoading = false;
      state.branchDetailsLoading = false;
      state.shipmentApiError = "";
      saveCompanyId(1);
      Object.assign(state.data, initialState.data);
    }),
    resetShareDeliveryModalData: create.reducer((state) => {
      // Object.assign(state, initialState); // uncomment for full reset ( not recommended )
      state.shareDeliveryFailed = false;
      state.shareDeliverySubmitted = false;
      state.shareDeliveryMessage = '';
    }),
    handleContractOrReservationSelection: create.reducer<{
      id: string;
      type: string;
      skipSelection?: boolean;
    }>((state, action) => {
      // store selected id and type
      state.selectedContractOrReservationId = action.payload.id;
      state.selectedOrderType = action.payload.type;
      state.skipSelection = !!action.payload.skipSelection;

      // find the details of order and store it.
      if (action.payload.type === ORDER_TYPE_RESERVATION) {
        state.orderDetails = state.data.reservation;
      } else if (action.payload.type === ORDER_TYPE_CONTRACT) {
        state.orderDetails =
          (state.data.contracts ?? []).find(
            (contract) => contract.contractId === action.payload.id
          ) ?? {};
      }
    }),
    fetchAuthToken: create.asyncThunk(
      async (params) => {
        const response = await getAuthToken(params);
        // The value we return becomes the `fulfilled` action payload
        return response;
      },
      {
        pending: (state) => {
          state.error = "";
          state.isTokenLoading = true;
        },
        fulfilled: (state, action) => {
          appLogger.logSuccess(
            `getAuthToken response: ${JSON.stringify({ state, action })}`
          );
          setCookie("dtaccesstoken", action.payload?.access_token ?? "");
        },
        rejected: (state, action) => {
          appLogger.logError(
            `API failed - getAuthToken ${action}`,
            false,
            'getAuthToken',
          )
          state.error = "Something went wrong."; // todo make it dynamic
        },
        settled: (state) => {
          state.isTokenLoading = false;
        },
      }
    ),
    fetchShareModalToken: create.asyncThunk(
      async (contractId: string) => {
        const response = await getShareDeliveryToken(contractId);
        // The value we return becomes the `fulfilled` action payload
        return response?.data ?? "";
      },
      {
        pending: (state) => {
          state.shareModalError = "";
          state.isShareModalLoading = true;
        },
        fulfilled: (state, action) => {
          appLogger.logSuccess(
            `fetchShareModalToken response: ${JSON.stringify({ state, action })}`
          );
          state.shareDeliveryToken = action.payload?.token ?? '';
        },
        rejected: (state, action) => {
          appLogger.logError(
            `API failed - fetchShareModalToken ${action}`,
            false,
            'fetchShareModalToken',
          )
          state.shareModalError = "Something went wrong."; // todo make it dynamic
        },
        settled: (state) => {
          state.isShareModalLoading = false;
        },
      }
    ),
    fetchIncExcPcList: create.asyncThunk(
      async () => {
        const response = await getIncExcPcList();
        // The value we return becomes the `fulfilled` action payload
        return response?.data ?? "";
      },
      {
        pending: (state) => {
          state.isExcIncListLoaded = false;
          state.isExcIncListFailed = false;
        },
        fulfilled: (state, action) => {
          appLogger.logSuccess(
            `fetchIncExcPcList response: ${JSON.stringify({ state, action })}`
          );
          state.isExcIncListLoaded = true;
          state.isExcIncListFailed = false;
          // todo enable transformation after mule api changes
          const { payload }: { payload: IncExcPcListResponse } = action ?? {};
          
          const transformedPayload = incExcPcDataTransformer(payload);
          state.inclusionList = transformedPayload?.inclusionList ?? [];
          state.exclusionList = transformedPayload?.exclusionList ?? [];
        },
        rejected: (state, action) => {
          appLogger.logError(
            `API failed - fetchIncExcPcList ${action}`,
            false,
            'fetchIncExcPcList',
          )
          state.isExcIncListLoaded = false;
          state.isExcIncListFailed = true;
        },
        settled: (state) => {
          // state.isShareModalLoading = false;
        },
      }
    ),
    fetchOrdersThroughToken: create.asyncThunk(
      async (
        data: { token: string, isAuthenticated: boolean }
      ) => {
        const response = await getContractsThroughToken(
          data.token,
        );
        return {
          ...response,
          ...data,
        };
      },
      {
        pending: (state) => {
          state.isLoading = true;
          state.error = "";
        },
        fulfilled: (state, action) => {
          appLogger.logSuccess(
            `fetchOrdersThroughToken response: ${JSON.stringify({ state, action })}`
          );
          const response = action.payload;
          const postalCode = `${response.jobsite?.jobZip ?? ''}`.trim();
          const isCanadian = isCanadianPostalCode(postalCode);
          saveCompanyId(isCanadian ? 2 : 1);
          state.data = action.payload;
          state.contractOrReservationId = (
            action.payload?.reservation?.origReservationId
            || action.payload?.contracts?.[0]?.contractId
          );
          state.zipOrPostalCode = postalCode;
        },
        rejected: (state, action) => {
          appLogger.logError(
            `API failed - fetchIncExcPcList ${action}`,
            false,
            'fetchIncExcPcList',
          )
          state.error = `Something went wrong.${action?.error?.message ? ` (${action?.error?.message})` : ''}`
        },
        settled: (state) => {
          state.isLoading = false;
        },
      }
    ),
    fetchOrders: create.asyncThunk(
      async (
        data: {
          contractId: string;
          postalCode: string;
          isAuthenticated: boolean;
          isEmployee: boolean;
        },
      ) => {
        const response = await getContracts(
          data.contractId,
          data.postalCode,
          data.isAuthenticated,
          data.isEmployee
        );
        // The value we return becomes the `fulfilled` action payload
        const transformedResponse = ordersDataTransformer(response);
        return {
          ...transformedResponse,
          ...data,
        };
      },
      {
        pending: (state) => {
          state.isLoading = true;
          state.error = "";
        },
        fulfilled: (state, action) => {
          appLogger.logSuccess(
            `fetchOrders response: ${JSON.stringify({ state, action })}`
          );
          state.data = action.payload;
          state.contractOrReservationId = action.payload.contractId;
          state.zipOrPostalCode = action.payload.postalCode;
        },
        rejected: (state, action) => {
          appLogger.logError(
            `API failed - fetchOrders ${action}`,
            false,
            'fetchOrders',
          )
          state.error = `Something went wrong.${action?.error?.message ? ` (${action?.error?.message})` : ''}`
        },
        settled: (state) => {
          state.isLoading = false;
        },
      }
    ),
    fetchShipmentDetails: create.asyncThunk(
      async (contractId: string, thunkApi: any) => {
        const { contract } = thunkApi.getState();
        const contractDetails = contract?.data?.contracts?.find(
          (c: Contract) => c.contractId === contractId
        );

        const response = await getShipments(contractId);
        const transformedResponse = transformShipmentResponse(response, contractDetails);
        return transformedResponse;
      },
      {
        pending: (state) => {
          state.shipmentDetailsLoading = true;
          state.shipmentApiError = "";
        },
        fulfilled: (state, action) => {
          appLogger.logSuccess(
            `fetchShipmentDetails response: ${JSON.stringify({ state, action })}`
          );
          state.stops = action.payload;
        },
        rejected: (state, action) => {
          appLogger.logError(
            `API failed - fetchShipmentDetails ${action}`,
            false,
            'fetchShipmentDetails',
          )
        },
        settled: (state) => {
          state.shipmentDetailsLoading = false;
        },
      }
    ),
    fetchBranchDetails: create.asyncThunk(
      async ({
        pcId,
      }: {
        pcId: number | string;
      }) => {
        const response = await getBranchDetails(pcId);
        return response.data?.data?.pcList?.[0];
      },
      {
        pending: (state) => {
          state.branchDetailsLoading = true;
        },
        fulfilled: (state, action) => {
          appLogger.logSuccess(
            `fetchBranchDetails response: ${JSON.stringify({ state, action })}`
          );
          state.branch = action.payload;
        },
        rejected: (state, action) => {
          appLogger.logError(
            `API failed - fetchBranchDetails ${action}`,
            false,
            'fetchBranchDetails',
          )
        },
        settled: (state) => {
          state.branchDetailsLoading = false;
        },
      }
    ),
    submitShareModalForm: create.asyncThunk(
      async ({
        email,
        phoneNumber,
        firstName,
        lastName,
        isCheckedEmail,
        isCheckedPhonenumber,
      }: ShareModalSubmitPayload, thunkApi: any) => {
        // fail safe
        if (!isCheckedEmail && !isCheckedPhonenumber) {
          throw new Error('Please select phone or email.')
        }
        const { contract } = thunkApi.getState();
        const isContract = contract.selectedOrderType === ORDER_TYPE_CONTRACT;
        const contractNumber = isContract
          ? (contract.orderDetails as Contract)?.contractId ?? ""
          : (contract.orderDetails as Reservation)?.origReservationId ?? "";
        const response = await submitShareDeliveryDetails({
          contractOrReservationId: contractNumber?.toString?.(),
          token: contract?.shareDeliveryToken,
          firstName,
          lastName,
          ...(isCheckedEmail && {email}),
          ...(isCheckedPhonenumber && {phoneNumber}),
          orderType: isContract ? 'O' : 'R'
        });
        return response;
      },
      {
        pending: (state) => {
          state.shareDeliverySubmitted = false;
          state.shareModalIsEmailSuccess = false;
          state.shareModalIsPhoneSuccess = false;
          state.shareDeliveryFailed = false;
          state.isShareModalLoading = true;
          state.shareDeliveryMessage = '';
        },
        fulfilled: (state, action) => {
          appLogger.logSuccess(
            `submitShareModalForm response: ${JSON.stringify({ state, action })}`
          );
          const { data, error } = action.payload;
          if (error) {
            state.shareModalIsEmailSuccess = data.isEmailSuccess;
            state.shareModalIsPhoneSuccess = data.isSmsSuccess;
            state.shareDeliveryMessage = data?.success ?? '';
            state.shareDeliverySubmitted = true;
          } else {
            state.shareDeliverySubmitted = true;
            state.shareModalIsEmailSuccess = true;
            state.shareModalIsPhoneSuccess = true;
            state.shareDeliveryMessage = data?.message ?? '';
          }
        },
        rejected: (state, action) => {
          appLogger.logError(
            `API failed - submitShareModalForm ${action}`,
            false,
            'submitShareModalForm',
          )
          state.shareDeliveryFailed = true;
        },
        settled: (state) => {
          state.isShareModalLoading = false;
        },
      }
    ),
  }),
  // You can define your selectors here. These selectors receive the slice
  // state as their first argument.
  selectors: {
    selectContractList: (contract) => {
      return contract.data;
    },
    selectContractDetails: (contract) => contract.orderDetails,
    selectIsLoading: (contract) => contract.isLoading,
    selectIsTokenLoading: (contract) => contract.isTokenLoading,
    selectApiError: (contract) => contract.error,
    selectUserData: (contract) => ({
      contractOrReservationId: contract.contractOrReservationId,
      zipOrPostalCode: contract.zipOrPostalCode,
    }),
    selectCurrentContractOrReservationId: (contract) =>
      contract.selectedContractOrReservationId,
    selectCurrentOrderType: (contract) => contract.selectedOrderType,
    selectShipments: (contract) => contract.shipments,
    selectStops: (contract) => contract.stops,
    selectBranch: (contract) => contract.branch,
    selectSkipSelection: (contract) => contract.skipSelection,
    selectShareDeliveryToken: (contract) => contract.shareDeliveryToken,
    selectIsExcIncListLoaded: (contract) => contract.isExcIncListLoaded,
    selectIsExcIncListFailed: (contract) => contract.isExcIncListFailed,
    selectExclusionList: (contract) => contract.exclusionList,
    selectInclusionList: (contract) => contract.inclusionList,
    selectShipmentDetailsLoading: (Contract) => Contract.shipmentDetailsLoading,
    selectBranchDetailsLoading: (Contract) => Contract.branchDetailsLoading,
    selectShareDeliverySubmitted: (Contract) => Contract.shareDeliverySubmitted,
    selectIsShareDeliveryLoading: (Contract) => Contract.isShareModalLoading,
    selectShareDeliveryFailed: (Contract) => Contract.shareDeliveryFailed,
    selectShareDeliveryMessage: (Contract) => Contract.shareDeliveryMessage,
    selectShareDeliveryIsPhoneSuccess: (Contract) => Contract.shareModalIsPhoneSuccess,
    selectShareDeliveryIsEmailSuccess: (Contract) => Contract.shareModalIsEmailSuccess,
    selectCurrentPathname: (Contract) => Contract.currentPathname,
    selectIsPageRefreshed: (Contract) => Contract.isPageRefreshed,
    selectIsUserProfileLoaded: (Contract) => Contract.isUserProfileLoaded,
    selectUserProfile: (Contract) => Contract.userProfile,
    selectAuthoringLabels: (Contract) => Contract.authoringLabels,
    selectIsLabelLoaded: (Contract) => Contract.isLabelsLoaded,
  },
});

// Action creators are generated for each case reducer function.
export const {
  // fetch data from api
  fetchOrders,
  fetchOrdersThroughToken,
  fetchAuthToken,
  fetchBranchDetails,
  fetchShipmentDetails,
  fetchShareModalToken,
  fetchIncExcPcList,
  // post data to api
  submitShareModalForm,
  // event handlers
  handleContractOrReservationSelection,
  resetStateData,
  resetShareDeliveryModalData,
  setCurrentPathname,
  setIsPageRefreshed,
  setAuthoringLabels,
  setUserProfile,
} = contractSlice.actions;

// Selectors returned by `slice.selectors` take the root state as their first argument.
export const {
  selectContractList,
  selectContractDetails,
  selectIsTokenLoading,
  selectIsLoading,
  selectApiError,
  selectUserData,
  selectCurrentContractOrReservationId,
  selectCurrentOrderType,
  selectShipments,
  selectStops,
  selectBranch,
  selectSkipSelection,
  selectShareDeliveryToken,
  selectIsExcIncListLoaded,
  selectIsExcIncListFailed,
  selectExclusionList,
  selectInclusionList,
  selectShipmentDetailsLoading,
  selectBranchDetailsLoading,
  selectShareDeliverySubmitted,
  selectShareDeliveryFailed,
  selectShareDeliveryMessage,
  selectIsShareDeliveryLoading,
  selectShareDeliveryIsPhoneSuccess,
  selectShareDeliveryIsEmailSuccess,
  selectCurrentPathname,
  selectIsPageRefreshed,
  selectIsUserProfileLoaded,
  selectUserProfile,
  selectAuthoringLabels,
  selectIsLabelLoaded,
} = contractSlice.selectors;
