import { DateTime, type DateTimeOptions, type DurationLikeObject } from "luxon";

export const fromISO = (dateStr: string, opts?: DateTimeOptions) => DateTime.fromISO(dateStr, opts)

// check if given date as string is a valid date or now
export const isValidDate = (dateStr: string) => {
  if (!dateStr) {
    return false; // empty string
  }

  const date = fromISO(dateStr);
  return date.isValid;
};

/**
 * Adds time to a date. Modelled after MySQL DATE_ADD function.
 * Example: dateAdd(new Date().toISOString(), 30, 'minute')  //returns 30 minutes from now.
 * 
 * @param dateStr  Date to start with
 * @param units  Number of units of the given interval to add.
 * @param interval  One of: hours, minutes, seconds
 */
export const dateAdd = (dateStr: string, units: number, interval: keyof DurationLikeObject): string => {
  if (!isValidDate(dateStr)) { // return if invalid date is provided
    return dateStr;
  }

  return fromISO(dateStr, {setZone: true}).plus({ [interval]: units}).toISO() ?? dateStr;
}

// convert UTC date to given TZ
export const utcToTz = (
  dateStr: string,
  setZone = false,
  format = ''
) => {
  return fromISO(dateStr, { setZone }).toFormat(format)
};

// convert given date to pc timezone
export const utcToPcTimezone = (
  dateStr: string,
  format = 'dd-MM-yyyy hh:mm a',
  timezoneAbbr = ''
): string => {
  const date = utcToTz(dateStr, true, format) ?? dateStr;

  return `${date} ${timezoneAbbr}`.trim();
}

const getTimezoneAbbr = (zoneName: string) => {
  let zoneAbbr = '';
  if (zoneName) {
    zoneAbbr = zoneName.split(/\s/).reduce((response, word) => response += word.slice(0,1), '')
  }
  if (zoneAbbr === "CUT") { // fix for UTC timezone
    zoneAbbr = "UTC";
  }
  return zoneAbbr;
};

// get visitor/user system time date and timezone abbr
export const getUserDateTime = () => {
  const date = DateTime.local();
  const zoneName = date.toFormat('ZZZZZ');

  const withTimezone = !zoneName;
  const format = `MMM d, yyyy 'at' hh:mm a${withTimezone ? ` ZZZZ` : ''}`;

  const zoneAbbr = getTimezoneAbbr(zoneName);

  return `${date.toFormat(format)} ${zoneAbbr}`.trim();
}
