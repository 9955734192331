import { createIntl, createIntlCache } from "react-intl";

import loadLocaleData from "../i18n";

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();

const loadIntl = () => {
  const { locale, messages } = loadLocaleData();

  return createIntl(
    {
      locale,
      messages,
    },
    cache
  );
};

export const translate = (id: string) => {
  const intl = loadIntl();
  return intl.formatMessage({ id });
};
