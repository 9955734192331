import type { Action, ThunkAction } from "@reduxjs/toolkit";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { persistStore, persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'; // Import session storage
import { type ContractSlice, contractSlice } from "../modules/contract/contractSlice";
import type { PersistPartial } from "redux-persist/es/persistReducer";

const persistConfig = {
  key: 'root',
  storage: storageSession,
  blacklist: ['contract']
};

const contractConfig = {
  key: 'contract',
  storage: storageSession,
  blacklist: [
    'error',
    'isLoading',
    'isTokenLoading',
    'shareDeliveryFailed',
    'shareDeliverySubmitted',
    'userProfile',
    'isUserProfileLoaded'
  ]
};

// `combineSlices` automatically combines the reducers using
// their `reducerPath`s, therefore we no longer need to call `combineReducers`.
// const rootReducer = combineSlices(contractSlice);
const rootReducer = combineReducers({
  contract: persistReducer(contractConfig, contractSlice.reducer),
});

// Infer the `RootState` type from the root reducer
export type RootState = ReturnType<typeof rootReducer>;

const persistedReducer = persistReducer(persistConfig, rootReducer)

// The store setup is wrapped in `makeStore` to allow reuse
// when setting up tests that need the same store config
export const makeStore = (preloadedState?: { contract: ContractSlice & PersistPartial; } & PersistPartial) => {
  const store = configureStore({
    reducer: persistedReducer,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: false
      }); //.concat(quotesApiSlice.middleware);
    },
    preloadedState,
  });
  // configure listeners using the provided defaults
  // optional, but required for `refetchOnFocus`/`refetchOnReconnect` behaviors
  setupListeners(store.dispatch);
  const persistor = persistStore(store);
  return { store, persistor };
};

export const { store, persistor } = makeStore();

// Infer the type of `store`
export type AppStore = typeof store;
// Infer the `AppDispatch` type from the store itself
export type AppDispatch = AppStore["dispatch"];

export type AppThunk<ThunkReturnType = void> = ThunkAction<
  ThunkReturnType,
  RootState,
  unknown,
  Action
>;
