import { fromISO, isValidDate, utcToPcTimezone } from "./timezoneUtils";

// format given date
export const formatDate = (dateStr: string) => {
  if (!isValidDate(dateStr)) { // return if invalid date is provided
    return dateStr;
  }
  
  return utcToPcTimezone(dateStr, 'MMM dd, yyyy');
}

export const formatTime = (dateStr: string, tzAbbr = '', clockFormat = true) => {
  if (!isValidDate(dateStr)) { // return if invalid date is provided
    return dateStr;
  }

  const withTimezone = !!tzAbbr;
  return utcToPcTimezone(dateStr, `hh:mm${clockFormat ? ' a' : ''}${withTimezone ? ` '${tzAbbr}'` : ''}`);
}

export const formatDateTime = (dateStr: string, tzAbbr = '') => {
  if (!isValidDate(dateStr)) { // return if invalid date is provided
    return dateStr;
  }

  const withTimezone = !!tzAbbr;
  return utcToPcTimezone(dateStr, `MMM dd, yyyy 'at' hh:mm a${withTimezone ? ` '${tzAbbr}'` : ''}`);
}


export const formatWeekDateTime = (dateStr: string, tzAbbr = '') => {
  if (!isValidDate(dateStr)) { // return if invalid date is provided
    return dateStr;
  }

  const withTimezone = !!tzAbbr;
  return utcToPcTimezone(dateStr, `ccc, MMM dd 'at' hh:mm a${withTimezone ? ` '${tzAbbr}'` : ''}`);
}

export const formatWeekDate = (dateStr: string) => {
  if (!isValidDate(dateStr)) { // return if invalid date is provided
    return dateStr;
  }

  return utcToPcTimezone(dateStr, 'ccc, MMM dd, yyyy');
}

export const isStartOfDay = (dateStr: string) => {
  if (!isValidDate(dateStr)) { // return false if invalid date is provided
    return false;
  }

  const a = fromISO(dateStr, { setZone: true }).toFormat('HH:mm:ss');
  const b = fromISO(dateStr, { setZone: true }).startOf('day').toFormat('HH:mm:ss')

  return a === b;
};

