import type { IncExcPcListData, IncExcPcListResponse, OrdersData } from "../modules/contract/contractSlice";

export const ordersDataTransformer = (data: OrdersData): OrdersData => {
  const dataCopy = JSON.parse(JSON.stringify(data));

  // placeholder for transformation

  return dataCopy;
};

export const incExcPcDataTransformer = (data: IncExcPcListResponse): IncExcPcListData => {
  const dataCopy = JSON.parse(JSON.stringify(data));

  const inclusionList = (dataCopy?.inclusionList ?? []).map((pc: string) => Number(pc.split('_')[0]))
  const exclusionList = (dataCopy?.exclusionList ?? []).map((pc: string) => Number(pc.split('_')[0]))

  return {
    inclusionList,
    exclusionList
  };
};

