import axios from "axios";
import type {
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { getApiConfigByEnv } from "../../utils/commonUtils";
import { appLogger } from '../../utils/logger';
import { cookieValue, setCookie } from "../../utils/cookieUtils";
import { getAuthToken } from "../authService";
import appEnv, { setupEnvVariables } from "../../env";
import { getCompanyId, isEmployeeUser, saveCompanyId } from "../../utils/authUtils";
/** setting up environement variables before setting up axios instance */
setupEnvVariables();

const config = getApiConfigByEnv();
const instance = axios.create({
  ...config,
});

interface RequestHeaders extends AxiosRequestHeaders {
  "x-correlation-Id": string;
  companyId: any;
  Authorization: string;
  loginToken: string;
  Accept?: string;
  "Content-Length"?: string;
  "User-Agent"?: string;
  "Content-Encoding"?: string;
  "Content-Type"?: string;
}
//TODO- Gaurav use later
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface AxiosRequestConfig {
  url: string;
  method?: string;
  headers?: RequestHeaders;
}

var getTrackerAuthTokenFromCookie = (): string => {
  var token = cookieValue("dtaccesstoken") || "";
  return `Bearer ${token}`;
};

var getAuthTokenFromCookie = (): string => {
  var token = cookieValue("accesstoken") || "";
  return `Bearer ${token}`;
};

var getLoginTokenFromCookie = (): string => {
  return cookieValue("loginToken") || "";
};

instance.interceptors.request.use(
  (req: InternalAxiosRequestConfig) => {
    //@ts-ignore to allow req.headers
    req.headers = {
      ...req.headers,
      "x-correlation-Id": appLogger.generateCorrelationId(),
      companyId: getCompanyId(),
      Authorization: getTrackerAuthTokenFromCookie(),
      loginToken: getLoginTokenFromCookie(),
      AuthorizationJWT: getAuthTokenFromCookie()
    };
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

/**
 * Wrap the interceptor in a function, so that it can be re-instantiated
 */
function createAxiosResponseInterceptor() {
  const interceptor = instance.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error) => {
      appLogger.logError(
        `Error reporting from axios interceptor ${JSON.stringify(error)}`,
        false,
        'createAxiosResponseInterceptor',
      )
      
      // cors or other unknown errors
      if (!error?.response) { 
        return Promise.reject(error);
      }
    
      const { response }  = error;

      // check if current user is employee
      if (isEmployeeUser()) {
        // check status code
        const isBadRequest = response?.status === 400 && response?.data?.error?.errorKey === "BAD_REQUEST";
        const isUSReq = Number(error?.config?.headers?.companyId ?? 0) === 1;

        // todo: remove company id switcher after discussing with prod team
        // suggestion: to add checkbox for country selection in emp login

        // switch company id (1<-->2), when API fails with BAD_REQUEST for employee login
        if (isBadRequest) {
          const updatedCompanyId = isUSReq ? "2" : "1";
          error.response.config.headers["companyId"] = updatedCompanyId;
          error.config.headers["companyId"] = updatedCompanyId;
          saveCompanyId(updatedCompanyId); // save in session storage
          return axios(error.response.config);
        }
      }

      if (
        Boolean(
          response?.config?.headers["isSilentOnError"]
        ) === true
      ) {
        return Promise.reject(error);
      }

      if (
        error?.response?.status >= 500
        || error?.response?.status === 429
      ) {
        return Promise.reject(error);
      }
      
      if (response?.status !== 401) {
        return Promise.reject(error);
      } 

      /*
       * When response code is 401, try to refresh the token.
       * Eject the interceptor so it doesn't loop in case
       * token refresh causes the 401 response.
       *
       * Must be re-attached later on or the token refresh will only happen once
       */
      axios.interceptors.response.eject(interceptor);

      const params: any = {
        client_id: appEnv.REACT_APP_CLIENT_ID,
        client_secret: appEnv.REACT_APP_CLIENT_SECRET,
        scope: 'Mulesoft'
      };
      appLogger.logWarning('API call failed, trying to refetch the token')
      return getAuthToken(params)
        .then((response) => {
          setCookie("dtaccesstoken", response?.access_token ?? "");
          appLogger.logSuccess(
            `Refresh Auth token response: ${JSON.stringify(response)}`
          );
          error.response.config.headers["Authorization"] =
            "Bearer " + response.access_token;
          // Retry the initial call, but with the updated token in the headers.
          // Resolves the promise if successful
          return axios(error.response.config);
        })
        .catch((e: any) => {
          appLogger.logError(
            'Auth token refresh call failed',
            false,
            'getAuthToken',
            params
          )
        })
        .finally(createAxiosResponseInterceptor); // Re-attach the interceptor by running the method;
    }
  );
}
createAxiosResponseInterceptor(); // Execute the method once during start


export default instance;
