import React from "react";
import type { FC, ReactNode } from "react";
import { createContext, useCallback, useContext, useMemo } from "react";
import { PAYLOAD_METHODS } from './analytics.constants';
import {
  pushEventPageUserEventToDataLayer,
  pushEventToDataLayerForCompleted,
  pushEventToDataLayerForFormError,
  pushEventToDataLayerForFormStarted,
  pushEventToDataLayerForFormViewed,
  pushEventForCtaClick
} from "./analytics.events";
import { useCheckUser } from "../../hooks/useUserTypeCheck";

interface AnalyticsContextProps {
    children?: ReactNode;
}

interface AnalyticsContextValue {
    sendPageEventForUser: (eventName: string) => void;
    sendEventForFormViewed: (eventName: string, formName: string, data?: any) => void;
    sendEventForFormStarted: (eventName: string, formName: string, fieldName: string, data?: any) => void;
    sendPageEventForFormError: (eventName: string, formName: string, fieldName: string, forError: string, data?: any) => void;
    sendEventForFormCompleted: (eventName: string, formName: string, data?: any) => void;
    sendEventForCtaClickOrPageView: (eventName: string, data?: any) => void;
}

const defaultAnalyticsContextValue: AnalyticsContextValue = {
  sendPageEventForUser: () => {
    throw new Error('AnalyticsContext is not initialized properly');
  },
  sendEventForFormViewed: () => {
    throw new Error('AnalyticsContext is not initialized properly');
  },
  sendEventForFormStarted: () => {
    throw new Error('AnalyticsContext is not initialized properly');
  },
  sendPageEventForFormError: () => {
    throw new Error('AnalyticsContext is not initialized properly');
  },
  sendEventForFormCompleted: () => {
    throw new Error('AnalyticsContext is not initialized properly');
  },
  sendEventForCtaClickOrPageView: () => {
    throw new Error('AnalyticsContext is not initialized properly');
  },
};

const AnalyticsContext = createContext<AnalyticsContextValue>(defaultAnalyticsContextValue);

const AnalyticsContextProvider: FC<AnalyticsContextProps> = ({ children }) => {

  const userType = useCheckUser();

  const sendPageEventForUser = useCallback((eventName: string): void => {
    PAYLOAD_METHODS.payloadForUserDataPageView(userType, (payload: any) => {
      pushEventPageUserEventToDataLayer(eventName, payload);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendEventForFormViewed = useCallback((eventName: string, formName: string, data?: any): void => {
    pushEventToDataLayerForFormViewed(eventName, formName, data);
  }, []);

  const sendEventForFormStarted = useCallback((eventName: string, formName: string, fieldName: string, data?: any): void => {
    pushEventToDataLayerForFormStarted(eventName, formName, fieldName, data);
  }, []);

  const sendPageEventForFormError = useCallback((eventName: string, formName: string, fieldName: string, formError: any, data: any): void => {
    PAYLOAD_METHODS.payloadForUserDataPageView(userType, (payload: any) => {
      pushEventToDataLayerForFormError(eventName, formName, fieldName, formError, payload);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendEventForFormCompleted = useCallback((eventName: string, formName: string, data?: any): void => {
    pushEventToDataLayerForCompleted(eventName, formName, data);
  }, []);

  const sendEventForCtaClickOrPageView = useCallback((eventName: string, data?: any): void => {
    pushEventForCtaClick(eventName, data);
  }, []);

  const contextValue = useMemo<AnalyticsContextValue>(() => {
    return {
      sendPageEventForUser,
      sendEventForFormViewed,
      sendEventForFormStarted,
      sendPageEventForFormError,
      sendEventForFormCompleted,
      sendEventForCtaClickOrPageView
    };
  }, [
    sendPageEventForUser,
    sendEventForFormViewed,
    sendEventForFormStarted,
    sendPageEventForFormError,
    sendEventForFormCompleted,
    sendEventForCtaClickOrPageView]);

  return <AnalyticsContext.Provider value={contextValue}>{children}</AnalyticsContext.Provider>;
};

export default AnalyticsContextProvider;

export const useAnalyticsContext = (): AnalyticsContextValue => useContext(AnalyticsContext);
