import { cookieValue } from '../utils/cookieUtils';
import { getProjectsFromCookie } from '../utils/commonUtils';
import { STORAGE_CONFIG } from '../constants/storageConstants';
import { USER_TYPE } from '../constants/userDetailsConstants';

export const useCheckUser = (): string => {
  const userType: string = USER_TYPE.GUEST;
  const userTypeStorage: string | null = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.USERTYPE);
    
  /* validate subsequent userType checking from localStorage */
  if (userTypeStorage) {
    return userTypeStorage;
  } else {
    /* validate initial authenticated user or guest user checking */
    const refreshToken: string | undefined = cookieValue(STORAGE_CONFIG.COOKIES.REFRESHTOKEN);
        
    /* validate normal authenticated user checking */
    if (refreshToken) {
      return deriveAuthenticatedUser();
    } else if (window.location !== window?.parent?.location) {
      /* validate punchout authenticated user checking */
      const punchoutRefreshToken: string | undefined = cookieValue(STORAGE_CONFIG.COOKIES.REFRESH_TOKEN);
            
      /* check if refresh token is present for punchout user */
      if (punchoutRefreshToken) {
        return deriveAuthenticatedUser();
      } else {
        return userType;
      }
    } else {
      /* validate guest user checking */
      return userType;
    }
  }
};

export const deriveAuthenticatedUser = (): string => {
  const projectCookies: any = getProjectsFromCookie();
  /* if account present, then credit user else cash user */
  if (projectCookies?.CurrentWynneAccount) {
    sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.USERTYPE, USER_TYPE.CREDIT);
    return USER_TYPE.CREDIT;
  } else {
    sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.USERTYPE, USER_TYPE.CASH);
    return USER_TYPE.CASH;
  }
};
