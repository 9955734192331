import { AUTH_TOKEN_API_URL } from "../constants/apiConstants";
import appEnv from "../env";
import axios from "axios";
import { appLogger } from "../utils/logger";

export interface AuthTokenApiParams {
  client_id: string;
  client_secret: string;
  scope: 'Mulesoft'
}

const getAuthToken = async (params: AuthTokenApiParams) => {
  try {
    const requestBody = new URLSearchParams();
    requestBody.append('grant_type', 'client_credentials');
    requestBody.append('client_id', params.client_id);
    requestBody.append('client_secret', params.client_secret);
    requestBody.append('scope', params.scope);

    // remove /web from api url for auth token service
    const serviceUrl = `${appEnv.REACT_APP_BASE_URL}${AUTH_TOKEN_API_URL}`.replace('/web', '');

    const { data } = await axios.post(serviceUrl, requestBody, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
    return data;
  } catch (e: any) {
    appLogger.logError(
      e?.message ?? 'getAuthToken API failed',
      false,
      "getAuthToken",
      params
    );
    throw e;
  }
};

export { getAuthToken };
