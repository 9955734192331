import {
  ORDER_STATUS_PLACED_TIMESTAMP,
  ORDER_STATUS_PLACED_TIMESTAMP_ABBR,
  ORDER_STATUS_RENTAL_START_DATE,
  ORDER_STATUS_RENTAL_START_DATE_ABBR,
  ORDER_STATUS_SHIPMENT_ETA_TIMESTAMP,
  ORDER_STATUS_SHIPMENT_ETA_TIMESTAMP_ABBR
} from "../constants/orderConstants";
import type { Contract, Shipment, Stop } from "../modules/contract/contractSlice";
import { checkIsException, convertShipmentTimestamps, mergePendingDeliveries } from "../modules/shipmentStatus/shipmentHelpers";

export const transformShipmentResponse = (response: { data: { stops: Stop[] }}, contractDetails: Contract | null) => {
  const stops = response?.data?.stops ?? [];

  // merge all pending deliveries
  const uniqueStops = mergePendingDeliveries(stops);

  return uniqueStops.map((stop: Stop) => {
    // append order placed timestamp in shipments array, used in stepper
    const shipments: Shipment[] = (stop.shipments ?? []).map(
      (shipment: Shipment) => {
        const updatedTimestamps = convertShipmentTimestamps(shipment);
        return {
          ...updatedTimestamps,
          [ORDER_STATUS_SHIPMENT_ETA_TIMESTAMP]:
            updatedTimestamps?.[ORDER_STATUS_SHIPMENT_ETA_TIMESTAMP] ?? contractDetails?.[ORDER_STATUS_RENTAL_START_DATE],
          [ORDER_STATUS_SHIPMENT_ETA_TIMESTAMP_ABBR]:
            !updatedTimestamps?.[ORDER_STATUS_SHIPMENT_ETA_TIMESTAMP]
              ? contractDetails?.[ORDER_STATUS_RENTAL_START_DATE_ABBR]
              : updatedTimestamps?.[ORDER_STATUS_SHIPMENT_ETA_TIMESTAMP_ABBR],
          [ORDER_STATUS_PLACED_TIMESTAMP]: contractDetails?.[ORDER_STATUS_PLACED_TIMESTAMP],
          [ORDER_STATUS_PLACED_TIMESTAMP_ABBR]
          : contractDetails?.[ORDER_STATUS_PLACED_TIMESTAMP_ABBR],
          isException: checkIsException(shipment), // flag to check exception
        }
      }
    );

    return {
      ...stop,
      shipments,
    };
  });
}